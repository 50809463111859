<template>
  <div>
    <v-dialog v-model="dialog" v-if="dialog" persistent max-width="350">
      <v-card v-if="worker != null">
        <v-card-title class="text-center" style="font-size: 14px !important">
          <b>{{ worker.PrsFullName }}</b>
        </v-card-title>
        <v-divider></v-divider>
        <v-col>
          <v-row>
            <v-col>
              <s-text
                v-model="menuWorker.MnwReason"
                autofocus
                label="Motivo de Asignación"
              ></s-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <s-select-generic
                itemtext="MntName"
                itemvalue="MntID"
                id="MntID"
                v-model="menuWorker.MntID"
                :config="configMenuSelect"
              ></s-select-generic>
            </v-col>
            <v-col v-if="this.$fun.getSecurity().IsLevelAdmin">
              <s-switch
                v-model="menuWorker.MnwIsFree"
                label="Es Gratuito"
              ></s-switch
            ></v-col>
          </v-row>
          <v-row>
            <v-col lg="6">
              <s-date
                label="Fecha Inicio"
                v-model="menuWorker.MnwValidityInit"
              ></s-date>
            </v-col>
            <v-col lg="6">
              <s-date
                nullable
                label="Fecha Fin"
                v-model="menuWorker.MnwValidityEnd"
              ></s-date>
            </v-col>
          </v-row>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialog = false;
              menuWorker = { ...menuWorkerReset };
            "
            >Cancelar</v-btn
          >
          <v-btn color="primary" @click="assing(menuWorker)">Asignar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col xs="12" sm="12" md="5" lg="5">
        <s-crud
          add
          addNoFunction
          @add="addWorker($event)"
          ref="crudWorker"
          @doubleClick="doubleClickWorker($event)"
          title="Trabajadores"
          :config="configUnassingned"
          :refreshTable="refreshWorker"
        >
        </s-crud>
      </v-col>
      <v-col xs="12" sm="12" md="7" lg="7">
        <s-crud
          :refreshTable="refresh"
          :config="configAssingned"
          @save="assing($event)"
          :filter="filter"
          excel
          edit
          remove
          title="Trabajadores Asignados"
          @removed="removedMenu()"
        >
          <template v-slot:filter>
            <v-col>
              <s-switch
                label="Listar Sin Descuento"
                v-model="filter.MnwIsFree"
              ></s-switch>
            </v-col>
            <v-divider></v-divider>
          </template>
          <template scope="props">
            <v-col>
              <v-row justify="center">
                <v-col :lg="IsLevelAdmin ? 3 : 6"
                  ><s-text
                    v-model="props.item.MnwReason"
                    autofocus
                    label="Motivo de Asignación"
                  ></s-text
                ></v-col>
                <v-col lg="3" v-if="IsLevelAdmin">
                  <s-switch
                    v-model="props.item.MnwIsFree"
                    label="Es Gratuito"
                  ></s-switch
                ></v-col>
              </v-row>

              <v-row justify="center">
                <v-col lg="3"
                  ><s-date
                    label="Fecha Inicio"
                    v-model="props.item.MnwValidityInit"
                  ></s-date
                ></v-col>

                <!--   <v-checkbox  v-model=props.item.EndIndeterminate
    ></v-checkbox>--><v-col lg="3">
                  <s-date
                    label="Fecha Fin"
                    v-model="props.item.MnwValidityEnd"
                    nullable
                  ></s-date
                ></v-col>
              </v-row>
            </v-col>
          </template>

          <template v-slot:MnwIsFree="{ row }">
            <v-simple-checkbox
              hide-details=""
              disabled=""
              style="margin: 0px; padding: 0px"
              :value="row.MnwIsFree == 1 ? true : false"
            >
            </v-simple-checkbox>
          </template>
        </s-crud>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MenuWorkerService from "./../../services/DinningHall/MenuTypeWorkerService";
import MenuService from "./../../services/DinningHall/MenuTypeService";
import MenuWorkerUnassingnedService from "./../../services/DinningHall/MenuWorkerUnassingnedService";
export default {
  name: "AssingMenu",
  components: {},
  data: () => ({
    dialog: false,
    worker: null,
    refresh: false,
    refreshWorker: false,
    configMenuSelect: {
      url: MenuService.getUrllist(),
      params: {},
      title: "Tipo de Menú",
    },
    menuWorkerReset: null,
    menuWorker: {
      MnwID: 0,
      MntID: 0,
      WkrID: 0,
      MnwReason: "",
      MnwValidityInit: "",
      MnwValidityEnd: null,
      EndIndeterminate: false,
      MnwIsFree: 0,
    },
    menu2: false,
    configUnassingned: {
      model: {
        PrsFullName: "string",
        WkrID: "ID",
        PrsDocumentNumber: "string",
      },
      service: MenuWorkerUnassingnedService,
      headers: [
        { text: "ID", value: "WkrID" },
        { text: "Trabajador", value: "PrsFullName" },
        { text: "Documento", value: "PrsDocumentNumber" },
      ],
    },
    filter: { MnwIsFree: 0 },
    configAssingned: {
      model: {
        EndIndeterminate: "booleam",
        MnwID: "int",
        WkrID: "ID",
        SecStatus: "booleam",
        MnwReason: "string",
        MnwValidityInit: "date",
        MnwValidityEnd: "date",
        PrsDocumentNumer: "string",
        PrsFullName: "string",
        MnwIsFree: "",
      },
      service: MenuWorkerService,
      headers: [
        { text: "ID", value: "MnwID" },
        { text: "Trabajador", value: "PrsFullName" },
        { text: "Documento", value: "PrsDocumentNumber" },
        { text: "Menú", value: "MenuTypeText" },
        { text: "Inicio", value: "MnwValidityInit" },
        { text: "Fin", value: "MnwValidityEnd" },
        { text: "Sin Desc", value: "MnwIsFree" },
      ],
    },
  }),
  computed: {
    IsLevelAdmin() {
      return this.$fun.getSecurity().IsLevelAdmin;
    },
  },
  methods: {
    addWorker(item) {
      if (item != null) this.doubleClickWorker(item);
    },
    removedMenu() {
      this.refreshWorker = !this.refreshWorker;
    },
    doubleClickWorker(item) {
      this.dialog = true;
      this.menuWorker.WkrID = item.WkrID;
      this.worker = item;
    },
    assing(item) {
      let obj = { ...item };
      this.$fun.alert("Seguro de guardar?", "question").then((val) => {
        if (val.value) {
          if (item.MntID == 0) {
            this.$fun.alert("Seleccione Menú", "warning");
            return;
          }
          if (item.MnwReason.trim().length < 1) {
            this.$fun.alert("Nombre Inválido", "warning");
            return;
          }
          MenuWorkerService.save(obj, localStorage.getItem("UsrID")).then(
            (r) => {
              this.refresh = !this.refresh;
              this.dialog = false;
              this.menuWorker = { ...menuWorkerReset };
              this.refreshWorker = !this.refreshWorker;
              this.$fun.alert("Agregado Correctamente", "success");
            }
          );
        }
      });
    },
  },
  created() {
    this.menuWorkerReset = { ...this.menuWorker };
  },
};
</script>
