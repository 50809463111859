import Vue from 'vue'

const menu="dinningHall/menutypeworker/";

export default {

    pagination(dtr){return Vue.prototype.$http.post(menu+"pagination",dtr);},

    save(obj,usr){
        return Vue.prototype.$http.post(menu+"save",obj,{params:{usr:usr}});
    },
    excel(dtr){
        return Vue.prototype.$http.post(menu+"reportexcel",dtr,{
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          });
    }
}